import { Button, Form, Input, Select } from 'antd';
//import { useHistory } from 'react-router-dom';
import { useCallback, useEffect, useState } from 'react';

import callApi from '../../Api/config';
import { toastError, toastSuccess } from '../../service/Toast';
import { SETTINGS } from '../../constants/EntityName';

import './style.scss';

type SettingsFormFields = {
    trialDays?: number;
    trialPrompt?: number;
    isTrialActive?: boolean;
};

const SiteSettings = () => {
    const [form] = Form.useForm();
    const [loading, setLoading] = useState(false);
    //const history = useHistory();

    // Fetch settings by ID
    const fetchSettings = useCallback(async () => {
        try {
            setLoading(true);
            const response: any = await callApi(`${SETTINGS}/1`, 'GET'); // Replace `1` with the desired settings ID

            if (response?.data?.isSuccess === 1 && response?.data?.data) {
                const {
                    trialDays: trialDays, // Map snake_case to camelCase
                    prompt: trialPrompt,
                    isTrialActive: isTrialActive,
                } = response.data.data;

                form.setFieldsValue({
                    trialDays,
                    trialPrompt,
                    isTrialActive,
                });
            } else {
                toastError(response?.message || 'Failed to fetch settings.');
            }
        } catch (error) {
            toastError('An error occurred while fetching settings.');
        } finally {
            setLoading(false);
        }
    }, [form]);

    // Update settings
    const handleSettingsUpdate = async (values: SettingsFormFields) => {
        try {
            setLoading(true);
            const payload = {
                trialDays: values.trialDays, // Convert camelCase back to snake_case
                prompt: values.trialPrompt,
                isTrialActive: values.isTrialActive,
            };
            const response: any = await callApi(`${SETTINGS}/1`, 'PATCH', payload); // Replace `1` with the desired settings ID

            console.log(response, 'Update Response');

            if (response?.data?.isSuccess) {
                toastSuccess(response?.data?.message || 'Settings updated successfully.');
                //history.push('/dashboard'); // Adjust the path as needed
            } else {
                toastError(response?.data?.message || 'Failed to update settings.');
            }
        } catch (error) {
            toastError('An error occurred while updating settings.');
        } finally {
            setLoading(false);
        }
    };

    // Fetch settings on component mount
    useEffect(() => {
        fetchSettings();
    }, [fetchSettings]);

    return (
        <div className='updateSettingsContainer'>
            <Form
                form={form}
                name='settingsForm'
                layout='vertical'
                onFinish={handleSettingsUpdate}
                initialValues={{ trialDays: 0, trialPrompt: 0 }}>
                <h1>Update Settings</h1>

                <Form.Item
                    name='trialDays'
                    label='Trial Days'
                    rules={[
                        { required: true, message: 'Please enter the number of trial days!' },
                        {
                            validator: (_, value) =>
                                value && Number(value) > 0
                                    ? Promise.resolve()
                                    : Promise.reject(new Error('Trial days must be a positive number!')),
                        },
                    ]}>
                    <Input
                        type='number'
                        placeholder='Enter trial days'
                        onChange={(e) => {
                            const value = e.target.value;
                            form.setFieldsValue({ trialDays: value ? Number(value) : '' });
                        }}
                    />
                </Form.Item>

                <Form.Item
                    name='trialPrompt'
                    label='Trial Prompts'
                    rules={[
                        { required: true, message: 'Please enter the trial prompt!' },
                        {
                            validator: (_, value) =>
                                value && Number(value) > 0
                                    ? Promise.resolve()
                                    : Promise.reject(new Error('Trial prompt must be a positive number!')),
                        },
                    ]}>
                    <Input
                        type='number'
                        placeholder='Enter trial prompt'
                        onChange={(e) => {
                            const value = e.target.value;
                            form.setFieldsValue({ trialPrompt: value ? Number(value) : '' });
                        }}
                    />
                </Form.Item>

                <Form.Item
                    name='isTrialActive'
                    label='Is Trial Active'
                    rules={[{ required: true, message: 'Please select whether the trial is active!' }]}>
                    <Select placeholder='Select trial status'>
                        <Select.Option value={true}>Yes</Select.Option>
                        <Select.Option value={false}>No</Select.Option>
                    </Select>
                </Form.Item>

                <Form.Item>
                    <Button type='primary' htmlType='submit' loading={loading}>
                        Update Settings
                    </Button>
                </Form.Item>
            </Form>
        </div>
    );
};

export default SiteSettings;
