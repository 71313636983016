import { errorMsg } from '../constants/messages';

const planListFormData = [
    {
        title: 'Plan Details',
        name: 'planName',
        label: 'Plan Name',
        fieldType: {
            name: 'text',
        },
        required: true,
        errorMsg: errorMsg,
        tableMeta: { title: 'Plan Name', allowAddRecordClick: true },
        isTableHeader: true,
        isAccordian: true,
    },
    {
        name: 'planType',
        label: 'Plan Type',
        fieldType: {
            name: 'select',
            options: [
                { name: 'weekly', value: 'weekly' },
                { name: 'monthly', value: 'monthly' },
                { name: 'yearly', value: 'yearly' },
            ],
        },
        required: true,
        errorMsg: errorMsg,
        tableMeta: { title: 'Plan Type' },
        isTableHeader: true,
        isAccordian: true,
    },

    {
        name: 'planPrice',
        label: 'Plan Price',
        fieldType: {
            name: 'number',
        },
        required: true,
        errorMsg: errorMsg,
        tableMeta: { title: 'Plan Price' },
        isTableHeader: true,
        isAccordian: true,
    },
    {
        name: 'numberOfPrompts',
        label: 'No of Prompts',
        fieldType: {
            name: 'decimal',
        },
        required: true,
        errorMsg: errorMsg,
        tableMeta: { title: 'No of Prompts' },
        isTableHeader: true,
        isAccordian: true,
    },
    {
        name: 'androidProductId',
        label: 'Android Product Id',
        fieldType: {
            name: 'string',
        },
        required: true,
        errorMsg: errorMsg,
        tableMeta: { title: 'Android Product Id' },
        isTableHeader: false,
        isAccordian: true,
    },
    {
        name: 'iosProductId',
        label: 'Ios Product Id',
        fieldType: {
            name: 'string',
        },
        required: true,
        errorMsg: errorMsg,
        tableMeta: { title: 'Ios Product Id' },
        isTableHeader: false,
        isAccordian: true,
    },
    {
        name: 'description1',
        label: 'Description 1',
        fieldType: {
            name: 'text',
        },
        required: true,
        errorMsg: errorMsg,
        tableMeta: { title: 'Description 1' },
        isTableHeader: false,
        isAccordian: true,
    },
    {
        name: 'description2',
        label: 'Description 2',
        fieldType: {
            name: 'text',
        },
        required: true,
        errorMsg: errorMsg,
        tableMeta: { title: 'Description 2' },
        isTableHeader: false,
        isAccordian: true,
    },
    {
        name: 'level',
        label: 'Level',
        fieldType: {
            name: 'interger',
        },
        required: true,
        errorMsg: errorMsg,
        tableMeta: { title: 'Level' },
        isTableHeader: true,
        isAccordian: true,
    },
    {
        name: 'isActive',
        label: 'Is Active',
        fieldType: {
            name: 'select',
            options: [
                { name: 'True', value: true },
                { name: 'False', value: false },
            ],
        },
        required: true,
        errorMsg: errorMsg,
        tableMeta: { title: 'Status' },
        isTableHeader: true,
        isAccordian: true,
    },
    {
        name: 'isUnlimited',
        label: 'Is Unlimited',
        fieldType: {
            name: 'select',
            options: [
                { name: 'True', value: true },
                { name: 'False', value: false },
            ],
        },
        required: true,
        errorMsg: errorMsg,
        tableMeta: { title: 'Is Unlimited' },
        isTableHeader: false,
        isAccordian: true,
    },
];

export { planListFormData };
