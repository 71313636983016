import { Button, Form, Input, Select, Spin, DatePicker } from 'antd';
import moment from 'moment';
//import { useHistory } from 'react-router-dom';
// import { useCallback, useMemo, useState } from 'react';
import { useState } from 'react';

//import callApi from '../../Api/config';
import { toastError, toastSuccess } from '../../service/Toast';
//import { SETTINGS } from '../../constants/EntityName';

import './style.scss';
import { BASE_API_URL } from '../../env';

//import { select } from 'redux-saga/effects';
//import { toDate } from 'date-fns';

type SettingsFormFields = {
    title?: string;
    message?: string;
    selectUser?: string;
    selectCategory?: string;
    fromDate?: string;
    toDate?: string;
};

// const { Option } = Select;

const NotificationSettings = () => {
    const [form] = Form.useForm();
    const [loading, setLoading] = useState<boolean>(false);
    // const [options, setOptions] = useState<any[]>([]);
    //const [selectedValues, setSelectedValues] = useState<string[]>([]);
    const [selectedValues2, setSelectedValues2] = useState<string>('');
    //const [selectedTokens, setSelectedTokens] = useState<string[]>([]);
    const [filteredOptions, setFilteredOptions] = useState([
        { value: 'notrial', label: 'No Trial' },
        { value: 'trial', label: 'Trial Expired' },
        { value: 'subscription', label: 'Subscription Expired' },
        { value: 'coupon', label: 'Coupon Expired' },
        { value: 'topup', label: 'Topup Expired' },
    ]);

    const handleSearch = (input: string) => {
        if (!input) {
            setFilteredOptions([
                { value: 'notrial', label: 'No Trial' },
                { value: 'trial', label: 'Trial Expired' },
                { value: 'subscription', label: 'Subscription Expired' },
                { value: 'coupon', label: 'Coupon Expired' },
                { value: 'topup', label: 'Topup Expired' },
            ]);
            return;
        }

        const filtered = filteredOptions.filter((option) => option.label.toLowerCase().includes(input.toLowerCase()));
        setFilteredOptions(filtered);
    };
    //const history = useHistory();

    const currentDate = moment().format('YYYY-MM-DD');
    console.log(currentDate);

    // Fetch settings by ID
    // const fetchSettings = async (searchValue: string) => {
    //     try {
    //         setLoading(true);
    //         const response: any = await fetch(`${BASE_API_URL}users?search=${searchValue}`);
    //         // const response: any = await callApi(`${SETTINGS}/1`, 'GET'); // Replace `1` with the desired settings ID
    //         const result = await response.json();
    //         if (result?.isSuccess === 1 && result?.data?.results) {
    //             const newOptions = result.data.results.map((item: any) => ({
    //                 key: item.id,
    //                 label: item.email,
    //                 token: item.deviceToken,
    //                 value: item.email,
    //             }));
    //             setOptions(newOptions);
    //             console.log(newOptions);
    //         } else {
    //             setOptions([]);
    //             toastError(response?.message || 'Failed to fetch settings.');
    //         }
    //     } catch (error) {
    //         toastError('An error occurred while fetching settings.');
    //         setOptions([]);
    //     } finally {
    //         setLoading(false);
    //     }
    // };

    // const debounce = (fn: Function, delay: number) => {
    //     let timeout: any;

    //     return function (...args: any[]) {
    //         clearTimeout(timeout);
    //         timeout = setTimeout(() => fn(...args), delay);
    //     };
    // };

    // const debouncedSearch = useMemo(
    //     () =>
    //         debounce((searchValue: string) => {
    //             if (searchValue.trim()) {
    //                 fetchSettings(searchValue);
    //             } else {
    //                 setOptions([]);
    //             }
    //         }, 500),
    //     [setOptions],
    // );

    // const handleSearch = useCallback(
    //     debounce((searchValue: string) => {
    //         if (searchValue.trim()) {
    //             fetchSettings(searchValue);
    //         } else {
    //             setOptions([]);
    //         }
    //     }, 500),
    //     [fetchSettings, setOptions],
    // );

    // const handleSearch = useCallback(
    //     (searchValue: string) => {
    //         debouncedSearch(searchValue);
    //     },
    //     [debouncedSearch],
    // );

    // const handleChange = (values: string[]) => {
    //     console.log(values);
    //     setSelectedValues(values);
    // };

    // const handleChange = (values: string[]) => {
    //     const selectedData = values.map((value) => {
    //         const selectedOption = options.find((option) => option.value === value);
    //         return {
    //             value,
    //             token: selectedOption ? selectedOption.token : '',
    //         };
    //     });

    //     setSelectedValues(selectedData.map((item) => item.value)); // Store emails

    //     //setSelectedTokens(selectedData.map((item) => item.token)); // Store tokens

    //     const uniqueTokens = Array.from(
    //         new Set(selectedData.map((item) => item.token).filter((token) => token !== 'string' && token !== '')),
    //     );

    //     setSelectedTokens(uniqueTokens);
    // };

    const handleChange2 = (value: string) => {
        //const selectedOption = options.find((option) => option.value === value);

        setSelectedValues2(value); // Store single value
        //setSelectedTokens(selectedOption ? [selectedOption.token] : []); // Store single token in array
    };

    // Update settings
    const formatDate = (dateString: string) => {
        const date = new Date(dateString);
        return date.toLocaleDateString('en-GB').replace(/\//g, '-'); // "04-03-2025"
    };
    const handleSendNotifications = async (values: SettingsFormFields) => {
        console.log(values.fromDate);
        try {
            setLoading(true);
            const payload = {
                //emails: selectedValues, // Select ke selected values
                // tokens: selectedTokens,
                title: values.title,
                message: values.message,
                trigger: values.selectCategory,
                fromDate: values.fromDate ? formatDate(values.fromDate) : null,
                toDate: values.toDate ? formatDate(values.toDate) : null,
            };
            // const response = await fetch(`${BASE_API_URL}users/send-notification-by-trigger`, {
            //     method: 'GET',
            //     headers: {
            //         'Content-Type': 'application/json',
            //     },
            //     body: JSON.stringify(payload),
            // });

            // const queryParams = new URLSearchParams({
            //     title: payload.title,
            //     message: payload.message,
            //     trigger: payload.trigger,
            //     from: payload.fromDate ? moment(payload.fromDate, 'DD-MM-YYYY').format('YYYY-MM-DD') : '',
            //     to: payload.toDate ? moment(payload.toDate, 'DD-MM-YYYY').format('YYYY-MM-DD') : '',
            // }).toString();

            const queryParams = new URLSearchParams(
                Object.entries({
                    title: payload.title || '',
                    message: payload.message || '',
                    trigger: payload.trigger || '',
                    from: payload.fromDate ? moment(payload.fromDate, 'DD-MM-YYYY').format('YYYY-MM-DD') : '',
                    to: payload.toDate ? moment(payload.toDate, 'DD-MM-YYYY').format('YYYY-MM-DD') : '',
                }).filter(([, value]) => value !== ''), // Remove empty values
            ).toString();

            const response = await fetch(`${BASE_API_URL}users/send-notification-by-trigger?${queryParams}`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                },
            });

            const result = await response.json();
            if (response.ok && result?.isSuccess) {
                //toastSuccess('Notifications sent successfully!');
                toastSuccess(result?.message || 'Notifications sent successfully!');
            } else {
                toastError(result?.message || 'Failed to send notifications.');
            }
        } catch (error) {
            toastError('An error occurred while sending notifications.');
        } finally {
            setLoading(false);
        }
    };

    // const handleSendNotifications = async (values: SettingsFormFields) => {
    //     try {
    //         setLoading(true);

    //         // Loop through each token and send API request
    //         const requests = selectedValues.map(async (token) => {
    //             const payload = {
    //                 token, // Single token per request
    //                 title: values.title,
    //                 message: values.message,
    //             };

    //             const response = await fetch('${BASE_API_URL}send-notification', {
    //                 method: 'POST',
    //                 headers: {
    //                     'Content-Type': 'application/json',
    //                 },
    //                 body: JSON.stringify(payload),
    //             });

    //             const result = await response.json();

    //             if (response.ok && result?.isSuccess) {
    //                 toastSuccess(`Notification sent successfully`);
    //             } else {
    //                 toastError(`Failed to send notification`);
    //             }
    //         });

    //         // Wait for all API calls to complete
    //         await Promise.all(requests);
    //     } catch (error) {
    //         toastError('An error occurred while sending notifications.');
    //     } finally {
    //         setLoading(false);
    //     }
    // };

    // Fetch settings on component mount
    // useEffect(() => {
    //     fetchSettings();
    // }, [fetchSettings]);

    return (
        <div className='updateSettingsContainer'>
            <Form
                form={form}
                name='settingsForm'
                layout='vertical'
                onFinish={handleSendNotifications}
                initialValues={{ title: '', message: '' }}>
                <h1>Notifications Settings</h1>

                <Form.Item
                    name='title'
                    label='Titile'
                    rules={[{ required: true, message: 'Please enter the number of title' }]}>
                    <Input
                        type='text'
                        placeholder='Enter Title'
                        onChange={(e) => {
                            const value = e.target.value;
                            form.setFieldsValue({ title: value ? value : '' });
                        }}
                    />
                </Form.Item>

                <Form.Item
                    name='message'
                    label='Message'
                    rules={[{ required: true, message: 'Please enter the Message' }]}>
                    <Input
                        type='message'
                        placeholder='Enter message'
                        onChange={(e) => {
                            const value = e.target.value;
                            form.setFieldsValue({ message: value ? value : '' });
                        }}
                    />
                </Form.Item>

                {/* <Form.Item
                    name='selectUser'
                    label='Select User'
                    rules={[{ required: false, message: 'Please select whether the trial is active!' }]}>
                    <Select
                        mode='multiple'
                        showSearch
                        placeholder='Search and select'
                        value={selectedValues}
                        onSearch={handleSearch}
                        onChange={handleChange}
                        notFoundContent={loading ? <Spin size='small' /> : 'No results found'}
                        filterOption={false} // Disable client-side filtering
                        style={{ width: '100%' }}>
                        {options.map((option) => (
                            <Option key={option.key} value={option.value} data-token={option.token}>
                                {option.label}
                            </Option>
                        ))}
                    </Select>
                </Form.Item> */}

                <Form.Item
                    name='selectCategory'
                    label='Select Category'
                    rules={[{ required: true, message: 'Please select whether the trial is active!' }]}>
                    <Select
                        showSearch
                        placeholder='Search and select'
                        value={selectedValues2}
                        onSearch={handleSearch}
                        onChange={handleChange2}
                        notFoundContent={loading ? <Spin size='small' /> : 'No results found'}
                        filterOption={false} // Disable client-side filtering
                        style={{ width: '100%' }}>
                        {filteredOptions.map((option) => (
                            <Select.Option key={option.value} value={option.value}>
                                {option.label}
                            </Select.Option>
                        ))}
                    </Select>
                </Form.Item>

                <Form.Item
                    name='fromDate'
                    label='From Date'
                    rules={[{ required: true, message: 'Please select From Date' }]}>
                    <DatePicker
                        style={{ width: '100%' }}
                        format='DD-MM-YYYY'
                        value={
                            form.getFieldValue('fromDate') ? moment(form.getFieldValue('fromDate'), 'DD-MM-YYYY') : null
                        }
                        onChange={(date, dateString) => {
                            console.log('Selected Date:', dateString);
                            form.setFieldsValue({ fromDate: dateString ? moment(dateString, 'DD-MM-YYYY') : null });
                        }}
                    />
                </Form.Item>

                <Form.Item
                    name='toDate'
                    label='To Date'
                    dependencies={['fromDate']}
                    rules={[
                        { required: true, message: 'Please select To Date' },
                        ({ getFieldValue }) => ({
                            validator(_, value) {
                                const fromDate = getFieldValue('fromDate');
                                if (
                                    !value ||
                                    !fromDate ||
                                    moment(fromDate, 'DD-MM-YYYY').isBefore(moment(value, 'DD-MM-YYYY'))
                                ) {
                                    return Promise.resolve();
                                }
                                return Promise.reject(new Error('To Date must be after From Date'));
                            },
                        }),
                    ]}>
                    <DatePicker
                        style={{ width: '100%' }}
                        format='DD-MM-YYYY'
                        value={form.getFieldValue('toDate') ? moment(form.getFieldValue('toDate'), 'DD-MM-YYYY') : null}
                        onChange={(date, dateString) => {
                            console.log('Selected Date:', dateString);
                            form.setFieldsValue({ toDate: dateString ? moment(dateString, 'DD-MM-YYYY') : null });
                        }}
                    />
                </Form.Item>

                <Form.Item>
                    <Button type='primary' htmlType='submit' loading={loading}>
                        Send Notifications
                    </Button>
                </Form.Item>
            </Form>
        </div>
    );
};

export default NotificationSettings;
