import { errorMsg } from '../constants/messages';

const couponListFormData = [
    {
        title: 'Coupon Details',
        name: 'title',
        label: 'Title',
        fieldType: {
            name: 'text',
        },
        required: true,
        errorMsg: errorMsg,
        tableMeta: { title: 'title', allowAddRecordClick: true },
        isTableHeader: true,
        isAccordian: true,
    },
    {
        name: 'couponCode',
        label: 'Promo Code',
        fieldType: {
            name: 'text',
        },
        required: true,
        errorMsg: errorMsg,
        tableMeta: { title: 'Promo Code' },
        isTableHeader: true,
        isAccordian: true,
    },
    {
        name: 'startTime',
        label: 'Start Date',
        fieldType: {
            name: 'date',
        },
        required: true,
        errorMsg: errorMsg,
        tableMeta: { title: 'Start Date' },
        isTableHeader: true,
        isAccordian: true,
    },
    {
        name: 'endTime',
        label: 'date',
        fieldType: {
            name: 'date',
        },
        required: true,
        errorMsg: errorMsg,
        tableMeta: { title: 'End Date' },
        isTableHeader: true,
        isAccordian: true,
    },
    {
        name: 'prompt',
        label: 'Total Prompt',
        fieldType: {
            name: 'number',
        },
        required: true,
        errorMsg: errorMsg,
        tableMeta: { title: 'Total Prompt' },
        isTableHeader: true,
        isAccordian: true,
    },
    {
        name: 'days',
        label: 'days',
        fieldType: {
            name: 'number',
        },
        required: true,
        errorMsg: errorMsg,
        tableMeta: { title: 'Total Prompt' },
        isTableHeader: true,
        isAccordian: true,
    },
    {
        name: 'isActive',
        label: 'Is Active',
        fieldType: {
            name: 'select',
            options: [
                { name: 'True', value: true },
                { name: 'False', value: false },
            ],
        },
        required: true,
        errorMsg: errorMsg,
        tableMeta: { title: 'Is Active' },
        isTableHeader: true,
        isAccordian: true,
    },
    // {
    //     name: 'description1',
    //     label: 'Description 1',
    //     fieldType: {
    //         name: 'text',
    //     },
    //     required: true,
    //     errorMsg: errorMsg,
    //     tableMeta: { title: 'Description 1' },
    //     isTableHeader: false,
    //     isAccordian: true,
    // },
    // {
    //     name: 'description2',
    //     label: 'Description 2',
    //     fieldType: {
    //         name: 'text',
    //     },
    //     required: true,
    //     errorMsg: errorMsg,
    //     tableMeta: { title: 'Description 2' },
    //     isTableHeader: false,
    //     isAccordian: true,
    // },
    // {
    //     name: 'level',
    //     label: 'Level',
    //     fieldType: {
    //         name: 'interger',
    //     },
    //     required: true,
    //     errorMsg: errorMsg,
    //     tableMeta: { title: 'Level' },
    //     isTableHeader: true,
    //     isAccordian: true,
    // },
    // {
    //     name: 'isActive',
    //     label: 'Is Active',
    //     fieldType: {
    //         name: 'select',
    //         options: [
    //             { name: 'True', value: true },
    //             { name: 'False', value: false },
    //         ],
    //     },
    //     required: true,
    //     errorMsg: errorMsg,
    //     tableMeta: { title: 'Status' },
    //     isTableHeader: true,
    //     isAccordian: true,
    // },
    // {
    //     name: 'isUnlimited',
    //     label: 'Is Unlimited',
    //     fieldType: {
    //         name: 'select',
    //         options: [
    //             { name: 'True', value: true },
    //             { name: 'False', value: false },
    //         ],
    //     },
    //     required: true,
    //     errorMsg: errorMsg,
    //     tableMeta: { title: 'Is Unlimited' },
    //     isTableHeader: false,
    //     isAccordian: true,
    // },
];

export { couponListFormData };
