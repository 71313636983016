/* eslint-disable no-unused-vars */
export enum AppRoutes {
    Products = '/products',
    Users = '/admin/users',
    Customers = '/admin/customers',
    Plans = '/admin/plans',
    TopUp = '/admin/topup',
    Coupons = '/admin/coupons',
    Versions = '/admin/versions',
    Notifications = '/admin/notifications',
    OrderList = '/orderList',
    Faq = '/faq',
    Question = '/questions',
    PrivacyPolicy = '/privacy-policy',
    TermsAndCondition = '/terms-condition',
    AuthResetPassword = '/auth/reset-password',
    AuthSignUp = '/auth/signup',
    ForgotPasswordEmailSend = '/admin/email/send',
    ResetForgotPassword = '/admin/reset-password',
    VerifyAdminEmail = '/admin/verify-email',
    UpdatePassword = '/admin/update-password',
    SiteSettings = '/admin/site-settings',
    Advertisement = '/Ads',
}
const entityArray = [
    { key: 'item-1', entityName: 'Users', entityUrl: AppRoutes.Users },
    { key: 'item-2', entityName: 'FAQs', entityUrl: AppRoutes.Faq },
    { key: 'item-3', entityName: 'Getting know Questions', entityUrl: AppRoutes.Question },
    { key: 'item-4', entityName: 'Advertisement', entityUrl: AppRoutes.Advertisement },
    { key: 'item-5', entityName: 'Customers', entityUrl: AppRoutes.Customers },
    { key: 'item-6', entityName: 'Plans', entityUrl: AppRoutes.Plans },
    { key: 'item-7', entityName: 'Top Ups', entityUrl: AppRoutes.TopUp },
    { key: 'item-8', entityName: 'Coupons', entityUrl: AppRoutes.Coupons },
    { key: 'item-9', entityName: 'Notification', entityUrl: AppRoutes.Notifications },
    { key: 'item-10', entityName: 'Versions', entityUrl: AppRoutes.Versions },
];

export { entityArray };
