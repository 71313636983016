import { errorMsg } from '../constants/messages';

const versionListFormData = [
    // {
    //     title: 'Versions Details',
    //     name: 'platform',
    //     label: 'Platform',
    //     fieldType: {
    //         name: 'text',
    //     },
    //     required: true,
    //     errorMsg: errorMsg,
    //     tableMeta: { title: 'platform', allowAddRecordClick: true },
    //     isTableHeader: true,
    //     isAccordian: true,
    // },
    {
        title: 'Versions Details',
        name: 'platform',
        label: 'Platform',
        fieldType: {
            name: 'select',
            options: [
                { name: 'Android', value: 'android' },
                { name: 'Ios', value: 'ios' },
            ],
        },
        required: true,
        errorMsg: errorMsg,
        tableMeta: { title: 'Platform', allowAddRecordClick: true },
        isTableHeader: true,
        isAccordian: true,
    },
    {
        name: 'version',
        label: 'Version',
        fieldType: {
            name: 'text',
        },
        required: true,
        errorMsg: errorMsg,
        tableMeta: { title: 'Version' },
        isTableHeader: true,
        isAccordian: true,
    },
    {
        name: 'time',
        label: 'Date',
        fieldType: {
            name: 'date',
        },
        required: false,
        errorMsg: errorMsg,
        tableMeta: { title: 'Time' },
        isTableHeader: true,
        isAccordian: true,
    },
    {
        name: 'isLatest',
        label: 'Latest',
        fieldType: {
            name: 'select',
            options: [
                { name: 'True', value: true },
                { name: 'False', value: false },
            ],
        },
        required: true,
        errorMsg: errorMsg,
        tableMeta: { title: 'Latest' },
        isTableHeader: true,
        isAccordian: true,
    },
    {
        name: 'isActive',
        label: 'Active',
        fieldType: {
            name: 'select',
            options: [
                { name: 'True', value: true },
                { name: 'False', value: false },
            ],
        },
        required: true,
        errorMsg: errorMsg,
        tableMeta: { title: 'Active' },
        isTableHeader: true,
        isAccordian: true,
    },
    {
        name: 'isBlocked',
        label: 'Blocked',
        fieldType: {
            name: 'select',
            options: [
                { name: 'True', value: true },
                { name: 'False', value: false },
            ],
        },
        required: true,
        errorMsg: errorMsg,
        tableMeta: { title: 'Blocked' },
        isTableHeader: true,
        isAccordian: true,
    },
    {
        name: 'isFutureBlocked',
        label: 'Future Blocked',
        fieldType: {
            name: 'select',
            options: [
                { name: 'True', value: true },
                { name: 'False', value: false },
            ],
        },
        required: true,
        errorMsg: errorMsg,
        tableMeta: { title: 'Future Blocked' },
        isTableHeader: true,
        isAccordian: true,
    },
    // {
    //     name: 'description1',
    //     label: 'Description 1',
    //     fieldType: {
    //         name: 'text',
    //     },
    //     required: true,
    //     errorMsg: errorMsg,
    //     tableMeta: { title: 'Description 1' },
    //     isTableHeader: false,
    //     isAccordian: true,
    // },
    // {
    //     name: 'description2',
    //     label: 'Description 2',
    //     fieldType: {
    //         name: 'text',
    //     },
    //     required: true,
    //     errorMsg: errorMsg,
    //     tableMeta: { title: 'Description 2' },
    //     isTableHeader: false,
    //     isAccordian: true,
    // },
    // {
    //     name: 'level',
    //     label: 'Level',
    //     fieldType: {
    //         name: 'interger',
    //     },
    //     required: true,
    //     errorMsg: errorMsg,
    //     tableMeta: { title: 'Level' },
    //     isTableHeader: true,
    //     isAccordian: true,
    // },
    // {
    //     name: 'isActive',
    //     label: 'Is Active',
    //     fieldType: {
    //         name: 'select',
    //         options: [
    //             { name: 'True', value: true },
    //             { name: 'False', value: false },
    //         ],
    //     },
    //     required: true,
    //     errorMsg: errorMsg,
    //     tableMeta: { title: 'Status' },
    //     isTableHeader: true,
    //     isAccordian: true,
    // },
    // {
    //     name: 'isUnlimited',
    //     label: 'Is Unlimited',
    //     fieldType: {
    //         name: 'select',
    //         options: [
    //             { name: 'True', value: true },
    //             { name: 'False', value: false },
    //         ],
    //     },
    //     required: true,
    //     errorMsg: errorMsg,
    //     tableMeta: { title: 'Is Unlimited' },
    //     isTableHeader: false,
    //     isAccordian: true,
    // },
];

export { versionListFormData };
