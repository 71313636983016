import { customErrorMsg, errorMsg } from '../constants/messages';
import { REGEX_PHONE_NUMBER } from '../utils/Regexp/Regex';

const customerListFormData = [
    {
        title: 'Customer Details',
        name: 'name',
        label: 'Name',
        fieldType: {
            name: 'text',
        },
        required: true,
        errorMsg: errorMsg,
        tableMeta: { title: 'Name', allowAddRecordClick: true },
        isTableHeader: true,
        isAccordian: true,
    },
    {
        name: 'AIname',
        label: 'AI Name',
        fieldType: {
            name: 'text',
        },
        required: true,
        errorMsg: errorMsg,
        tableMeta: { title: 'AI Name' },
        isTableHeader: true,
        isAccordian: true,
    },
    {
        name: 'gender',
        label: 'Gender',
        fieldType: {
            name: 'text',
        },
        required: true,
        errorMsg: errorMsg,
        tableMeta: { title: 'Gender' },
        isTableHeader: true,
        isAccordian: true,
    },
    {
        name: 'email',
        label: 'Email',
        fieldType: {
            name: 'email',
        },
        rules: [{ pattern: REGEX_PHONE_NUMBER, message: customErrorMsg('phone number') }],
        required: true,
        errorMsg: errorMsg,
        tableMeta: { title: 'Email' },
        isTableHeader: true,
        isAccordian: true,
    },
    {
        name: 'isBlocked',
        label: 'Is Blocked',
        fieldType: {
            name: 'select',
            options: [
                { name: 'True', value: true },
                { name: 'False', value: false },
            ],
        },
        required: true,
        errorMsg: errorMsg,
        tableMeta: { title: 'Status' },
        isTableHeader: true,
        isAccordian: true,
    },
    {
        name: 'city',
        label: 'City',
        fieldType: {
            name: 'text',
        },
        required: false,
        errorMsg: errorMsg,
        tableMeta: { title: 'City' },
        isTableHeader: true,
        isAccordian: false,
    },
    {
        name: 'download',
        label: 'download',
        fieldType: {
            name: 'text',
        },
        required: false,
        errorMsg: errorMsg,
        tableMeta: { title: 'Download', columnType: 'download', allowAddNewRedirectOnClicks: true },
        isTableHeader: true,
        isAccordian: false,
    },
    {
        name: 'action',
        label: 'action',
        fieldType: {
            name: 'text',
        },
        required: false,
        errorMsg: errorMsg,
        tableMeta: { title: 'Action', columnType: 'action', allowAddRedirectOnClicks: true },
        isTableHeader: true,
        isAccordian: true,
    },
];

export { customerListFormData };
