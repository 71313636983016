/* eslint-disable no-unused-vars */
import { Button } from 'antd';
import { useEffect, useState } from 'react';

import Search from '../../../components/Search/Search';
import SearchByCity from '../../../components/Search/SearchByCity';
import SearchByPhone from '../../../components/Search/SearchByPhone';
import SearchByState from '../../../components/Search/SearchByState';
import { BASE_API_URL } from '../../../env';

interface IDefaultTableHeader {
    headerName?: string;
    totalCount?: number;
    downloadUser?: boolean;
    countText?: string;
    setSearchQuery: (value: any) => void;
    setPhoneSearchQuery: (value: any) => void;
    setCitySearchQuery: (value: any) => void;
    setStateSearchQuery: (value: any) => void;
    onAdd: () => void;
    addName?: string;
    disableAdd?: boolean;
    disableSearch?: boolean;
}

const DefaultTableHeader = (props: IDefaultTableHeader) => {
    const { disableSearch } = props;
    const [garageSearch, setGarageSearch] = useState('');
    const [selectedOption, setSelectedOption] = useState('User');

    useEffect(() => {
        if (props.addName === 'Garage Commission') {
            setGarageSearch('Garage');
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const [modalVisible, setModalVisible] = useState<boolean>(false);

    const getSearchPlaceholder = (value: string | undefined) => {
        if (value === 'Order' || value === 'Refunds') {
            return 'Order Id';
        } else {
            return `${value?.toLowerCase()} name`;
        }
    };

    const getPhoneSearchPlaceholder = (value: string | undefined) => {
        if (value === 'Order') {
            return 'Phone No';
        } else {
            return `${value?.toLowerCase()} name`;
        }
    };

    const getCitySearchPlaceholder = (value: string | undefined) => {
        if (value === 'Order') {
            return 'City Name';
        } else {
            return `${value?.toLowerCase()} name`;
        }
    };

    const getStateSearchPlaceholder = (value: string | undefined) => {
        if (value === 'Order') {
            return 'State Name';
        } else {
            return `${value?.toLowerCase()} name`;
        }
    };

    const handleSelectChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
        setSelectedOption(event.target.value);
    };

    const onDownloadClick = async () => {
        const fromDateElement = document.getElementById('fromDate') as HTMLInputElement | null;
        const toDateElement = document.getElementById('toDate') as HTMLInputElement | null;

        const formatDate = (date: string): string => {
            if (!date) return '';
            const [year, month, day] = date.split('-');
            return `${day}-${month}-${year}`;
        };

        const fromDate = formatDate(fromDateElement?.value || '');
        const toDate = formatDate(toDateElement?.value || '');

        try {
            // API call using Axios
            const response = await fetch(`${BASE_API_URL}users/download/user?from=${fromDate}&to=${toDate}`);

            if (!response.ok) {
                alert('No Data Exist');
                throw new Error('Failed to fetch file');
            }

            const blob = await response.blob();
            const url = URL.createObjectURL(blob);

            const newTab = window.open(url, '_blank');
            if (fromDateElement) fromDateElement.value = '';
            if (toDateElement) toDateElement.value = '';

            newTab?.addEventListener('load', () => {
                URL.revokeObjectURL(url);
            });
        } catch (error) {
            console.error('Error while downloading file:', error);
        }
    };
    const onTrialDownloadClick = async () => {
        const fromDateElement = document.getElementById('fromDate') as HTMLInputElement | null;
        const toDateElement = document.getElementById('toDate') as HTMLInputElement | null;

        const formatDate = (date: string): string => {
            if (!date) return '';
            const [year, month, day] = date.split('-');
            return `${day}-${month}-${year}`;
        };

        const fromDate = formatDate(fromDateElement?.value || '');
        const toDate = formatDate(toDateElement?.value || '');

        try {
            // API call using Axios
            const response = await fetch(
                `${BASE_API_URL}users/reports/download?from=${fromDate}&to=${toDate}&type=trial`,
            );

            if (!response.ok) {
                alert('No Data Exist');
                throw new Error('Failed to fetch file');
            }

            const blob = await response.blob();
            const url = URL.createObjectURL(blob);

            const newTab = window.open(url, '_blank');
            if (fromDateElement) fromDateElement.value = '';
            if (toDateElement) toDateElement.value = '';

            newTab?.addEventListener('load', () => {
                URL.revokeObjectURL(url);
            });
        } catch (error) {
            console.error('Error while downloading file:', error);
        }
    };
    const onSubscriptionDownloadClick = async () => {
        const fromDateElement = document.getElementById('fromDate') as HTMLInputElement | null;
        const toDateElement = document.getElementById('toDate') as HTMLInputElement | null;

        const formatDate = (date: string): string => {
            if (!date) return '';
            const [year, month, day] = date.split('-');
            return `${day}-${month}-${year}`;
        };

        const fromDate = formatDate(fromDateElement?.value || '');
        const toDate = formatDate(toDateElement?.value || '');

        try {
            // API call using Axios
            const response = await fetch(
                `${BASE_API_URL}users/reports/download?from=${fromDate}&to=${toDate}&type=subscription`,
            );

            if (!response.ok) {
                alert('No Data Exist');
                throw new Error('Failed to fetch file');
            }

            const blob = await response.blob();
            const url = URL.createObjectURL(blob);

            const newTab = window.open(url, '_blank');
            if (fromDateElement) fromDateElement.value = '';
            if (toDateElement) toDateElement.value = '';

            newTab?.addEventListener('load', () => {
                URL.revokeObjectURL(url);
            });
        } catch (error) {
            console.error('Error while downloading file:', error);
        }
    };
    const onConversionDownloadClick = async () => {
        const fromDateElement = document.getElementById('fromDate') as HTMLInputElement | null;
        const toDateElement = document.getElementById('toDate') as HTMLInputElement | null;

        const formatDate = (date: string): string => {
            if (!date) return '';
            const [year, month, day] = date.split('-');
            return `${day}-${month}-${year}`;
        };

        const fromDate = formatDate(fromDateElement?.value || '');
        const toDate = formatDate(toDateElement?.value || '');

        try {
            // API call using Axios
            const response = await fetch(`${BASE_API_URL}users/reports/conversion?from=${fromDate}&to=${toDate}`);

            if (!response.ok) {
                alert('No Data Exist');
                throw new Error('Failed to fetch file');
            }

            const blob = await response.blob();
            const url = URL.createObjectURL(blob);

            const newTab = window.open(url, '_blank');
            if (fromDateElement) fromDateElement.value = '';
            if (toDateElement) toDateElement.value = '';

            newTab?.addEventListener('load', () => {
                URL.revokeObjectURL(url);
            });
        } catch (error) {
            console.error('Error while downloading file:', error);
        }
    };

    return (
        <>
            {props?.downloadUser && (
                <div className='actionbar'>
                    <div className='flex align-bottom searchBoxContainer'>
                        <div className='searchBox' style={{ width: 'auto', marginRight: '16px' }}>
                            <label htmlFor='fromDate'></label>
                            <input
                                type='date'
                                name='fromDate'
                                id='fromDate'
                                style={{ border: '0', boxShadow: 'none', outline: 'none' }}
                            />
                        </div>
                        <div className='searchBox' style={{ width: 'auto', marginRight: '16px' }}>
                            <label htmlFor='toDate'></label>
                            <input
                                type='date'
                                name='toDate'
                                id='toDate'
                                style={{ border: '0', boxShadow: 'none', outline: 'none' }}
                            />
                        </div>
                        <select
                            name=''
                            id=''
                            className='ant-btn ant-btn-primary'
                            style={{ background: 'gray', borderColor: 'gray', textAlign: 'left' }}
                            onChange={handleSelectChange}>
                            <option value='User'>Download User</option>
                            <option value='Trial'>Download Trial</option>
                            <option value='Subscription'>Download Subscription</option>
                            <option value='Conversion'>Download Conversion</option>
                        </select>
                        {selectedOption === 'User' && (
                            <Button
                                type='primary'
                                style={{ background: 'gray', borderColor: 'gray', marginLeft: '16px' }}
                                onClick={onDownloadClick}>
                                Download
                            </Button>
                        )}
                        {selectedOption === 'Trial' && (
                            <Button
                                type='primary'
                                style={{ background: 'gray', borderColor: 'gray', marginLeft: '16px' }}
                                onClick={onTrialDownloadClick}>
                                Download
                            </Button>
                        )}
                        {selectedOption === 'Subscription' && (
                            <Button
                                type='primary'
                                style={{ background: 'gray', borderColor: 'gray', marginLeft: '16px' }}
                                onClick={onSubscriptionDownloadClick}>
                                Download
                            </Button>
                        )}
                        {selectedOption === 'Conversion' && (
                            <Button
                                type='primary'
                                style={{ background: 'gray', borderColor: 'gray', marginLeft: '16px' }}
                                onClick={onConversionDownloadClick}>
                                Download
                            </Button>
                        )}
                    </div>
                </div>
            )}
            <div className='actionbar'>
                <div className='flex align-bottom'>
                    <h2>{props.headerName}</h2>
                    <span>
                        {props.totalCount} {props.countText ? props.countText : 'Items'}
                    </span>
                </div>
                <div className='flex align-center'>
                    {!disableSearch && (
                        <Search
                            setSearchQuery={props.setSearchQuery}
                            placeholderValue={getSearchPlaceholder(!garageSearch ? props.addName : garageSearch)}
                            leftIconImg={true}
                        />
                    )}

                    {props.headerName === 'Order List' && (
                        <SearchByPhone
                            setPhoneSearchQuery={props.setPhoneSearchQuery}
                            placeholderValue={getPhoneSearchPlaceholder(props.addName)}
                            leftIconImg={true}
                        />
                    )}
                    {props.headerName === 'Order List' && (
                        <SearchByCity
                            setCitySearchQuery={props.setCitySearchQuery}
                            placeholderValue={getCitySearchPlaceholder(props.addName)}
                            leftIconImg={true}
                        />
                    )}

                    {props.headerName === 'Order List' && (
                        <SearchByState
                            setStateSearchQuery={props.setStateSearchQuery}
                            placeholderValue={getStateSearchPlaceholder(props.addName)}
                            leftIconImg={true}
                        />
                    )}
                    <div className='addBtn'>
                        <Button disabled={props.disableAdd} onClick={props.onAdd}>
                            + Add {props.addName}
                        </Button>
                    </div>
                    {(props.headerName === 'Option Type' || props.headerName === 'Collection Type') && (
                        <div className='addBtn'>
                            <Button
                                onClick={() => {
                                    setModalVisible(true);
                                }}>
                                Change {props.headerName} position
                            </Button>
                        </div>
                    )}
                </div>
            </div>
        </>
    );
};

DefaultTableHeader.defaultProps = {
    addName: '',
};

export default DefaultTableHeader;
