import { useState } from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';

import Sidebar from '../../components/SideBar';
import { AppRoutes, entityArray } from '../../constants/AppConstant';

import Users from '../UserList';
import Customers from '../CustomerList';
import Plans from '../PlanList';
import TopupList from '../TopupList';
import Coupons from '../CouponList';
import Versions from '../VersionList';
import FaqModule from '../FaqModule';
import NotificationSettings from '../Notifications';

import QuestionModule from '../QuestionModule';
import PrivacyPolicy from '../PrivacyPolicy';
import TermsAndCondition from '../TermsAndCondition';
import UpdatePassword from '../UpdatePassword';
import SiteSettings from '../SiteSettings';
import './styles.scss';
import Advertisement from '../Advertisement';

const Landing = () => {
    const [moduleList] = useState<any>(entityArray);

    return (
        <Router>
            <div className='landing-box'>
                <Sidebar title='MMAI' entityArr={moduleList} />
                <div className='landing-box-content'>
                    <Switch>
                        <Route exact path='/' component={Users} />
                        <Route exact path={AppRoutes.Users} component={Users} />

                        <Route exact path={`${AppRoutes.Users}`}>
                            <Users />
                        </Route>

                        <Route exact path={`${AppRoutes.Customers}`}>
                            <Customers />
                        </Route>

                        <Route exact path={`${AppRoutes.Plans}`}>
                            <Plans />
                        </Route>

                        <Route exact path={`${AppRoutes.TopUp}`}>
                            <TopupList />
                        </Route>

                        <Route exact path={`${AppRoutes.Coupons}`}>
                            <Coupons />
                        </Route>

                        <Route exact path={`${AppRoutes.Versions}`}>
                            <Versions />
                        </Route>

                        <Route exact path={`${AppRoutes.Notifications}`}>
                            <NotificationSettings />
                        </Route>

                        <Route exact path={`${AppRoutes.Faq}`}>
                            <FaqModule />
                        </Route>
                        <Route exact path={`${AppRoutes.Question}`}>
                            <QuestionModule />
                        </Route>
                        <Route exact path={`${AppRoutes.PrivacyPolicy}`}>
                            <PrivacyPolicy />
                        </Route>
                        <Route exact path={`${AppRoutes.TermsAndCondition}`}>
                            <TermsAndCondition />
                        </Route>

                        <Route exact path={`${AppRoutes.UpdatePassword}`}>
                            <UpdatePassword />
                        </Route>

                        <Route exact path={`${AppRoutes.SiteSettings}`}>
                            <SiteSettings />
                        </Route>

                        <Route exact path={`${AppRoutes.Advertisement}`}>
                            <Advertisement />
                        </Route>
                    </Switch>
                </div>
            </div>
        </Router>
    );
};

export default Landing;
